import React from "react";
import View from "components/View";
import { css } from "glamor";
import colors from "material-colors";

export default function Separator() {
  return (
    <View
      {...css({
        backgroundColor: colors.grey[100],
        height: 1,
        marginTop: 8,
        marginBottom: 8
      })}
    />
  );
}
