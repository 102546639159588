import React from "react";
import colors from "material-colors";
import { css } from "glamor";

const buttonCss = css({
  textTransform: "uppercase",
  borderRadius: 8,
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  //                boxShadow: 'inset 8px 4px 24px 0px rgba(0,0,0,0.75)',
  boxSizing: "border-box",
  padding: 8,
  margin: 3,
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  cursor: "pointer",
  fontFamily: "sans-serif",
  alignItems: "center",
  justifyContent: "center",
  color: colors.grey[100],
  letterSpacing: 2,
  flex: 1,
  opacity: 1,
  ":hover": {
    opacity: 0.9
  }
});

export const theme = {
  outline: css({
    color: colors.grey[100],
    backgroundColor: "transparent",
    border: `1px solid ${colors.grey[100]}`
  }),
  yellow: css({
    borderColor: colors.yellow[500],
    backgroundColor: colors.yellow[500],
    color: colors.grey[900]
  }),
  grey: css({
    borderColor: colors.grey[500],
    backgroundColor: colors.grey[500],
    color: colors.grey[900]
  }),
  white: css({
    borderColor: colors.grey[100],
    backgroundColor: colors.grey[100],
    color: colors.grey[900]
  }),
  squareTransparent: css({
    borderRadius: 0,
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: colors.grey[100]
  }),
  squareYellow: css({
    borderColor: colors.yellow[500],
    backgroundColor: colors.yellow[500],
    color: colors.grey[900],
    borderRadius: 0
  }),
  fullWidth: css({ width: "100%" })
};

const Button = React.memo(({ onPress, title, fullWidth, ...props }) => {
  return (
    <button
      title={title}
      children={title}
      {...buttonCss}
      {...theme[props.theme || "squareTransparent"]}
      {...(fullWidth ? theme.fullWidth : null)}
      {...props}
      onClick={onPress}
    />
  );
});

export default Button;
