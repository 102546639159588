import React, { useMemo } from "react";
import { css } from "glamor";
import Column from "components/Column";
import HighlightButton from "components/HighlightButton";
import { theme as buttonTheme } from "components/Button";
import Button from "components/Button";
import * as styles from "styles";
import createMemoizedSplit from "helper/createMemoizedSplit";
import {
  useDispatch,
  useSelector,
  getCurrentTick,
  getDurations,
  getInstrument,
  getCalculatedNotes
} from "state";
import { scale } from "@tonaljs/scale";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";

const splitEvent = createMemoizedSplit("_");
const splitDurations = createMemoizedSplit("|");

export default React.memo(function Beat({
  style,
  event,
  index,
  instrument,
  isPiano
}) {
  const dispatch = useDispatch();
  const durations = useSelector(getDurations);
  const currentTick = useSelector(getCurrentTick);
  const instrumentObject = useSelector(getInstrument(instrument));
  const notes = instrumentObject
    ? getCalculatedNotes(
        scale(
          [
            instrumentObject.scaleKey,
            instrumentObject.scale.toLowerCase()
          ].join(" ")
        ).notes
      )
    : [];
  const noteSelectItems = useMemo(
    () =>
      notes.map(name => (
        <MenuItem
          key={name}
          onSelect={() =>
            dispatch({
              type: "setInstrumentNote",
              payload: { instrument, note: name, index }
            })
          }
        >
          {name}
        </MenuItem>
      )),
    [notes, dispatch, index, instrument]
  );
  const durationselectItems = useMemo(
    () =>
      splitDurations(durations).map(name => (
        <MenuItem
          key={name}
          onSelect={() =>
            dispatch({
              type: "setInstrumentDuration",
              payload: { instrument, duration: name, index }
            })
          }
        >
          {name}
        </MenuItem>
      )),
    [durations, dispatch, index, instrument]
  );
  const selectStyle = useMemo(
    () => ({
      ...buttonTheme.outline,
      ...css({
        margin: 4,
        fontSize: "inherit",
        cursor: "pointer"
      })
    }),
    []
  );

  const [on, currentNote, dur] = splitEvent(event || "");
  if (isPiano) {
    return (
      <div style={style}>
        {notes
          .map(note => {
            const isActiveNote = currentNote === note && on;
            return (
              <Button
                key={note}
                className={`hb-${index}`}
                fullWidth
                {...css({ fontSize: 10, height: 10 })}
                theme={isActiveNote ? "squareYellow" : "squareTransparent"}
                onPress={() => {
                  if (isActiveNote || !on) {
                    dispatch({
                      type: "toggleInstrumentOn",
                      payload: { instrument, index }
                    });
                  }
                  dispatch({
                    type: "setInstrumentNote",
                    payload: { instrument, note, index }
                  });
                }}
              >
                {note}
              </Button>
            );
          })
          .reverse()}
      </div>
    );
  }
  return (
    <Column {...styles.flexOne} style={style} key={index}>
      <Menu>
        <MenuButton {...selectStyle}>
          {currentNote} <span aria-hidden>▾</span>
        </MenuButton>
        <MenuList>{noteSelectItems}</MenuList>
      </Menu>
      <Menu>
        <MenuButton {...selectStyle}>
          {dur} <span aria-hidden>▾</span>
        </MenuButton>
        <MenuList>{durationselectItems}</MenuList>
      </Menu>
      <HighlightButton
        on={on}
        highlighted={index === currentTick}
        index={index}
        key={index}
        title={instrument}
        onPress={() => {
          dispatch({
            type: "toggleInstrumentOn",
            payload: { instrument, index }
          });
        }}
      />
    </Column>
  );
});
