import React from "react";
import Beat from "components/Beat";
import Row from "components/Row";
import View from "components/View";
import * as styles from "styles";
import InstrumentControl from "components/InstrumentControl";
import { getInstrument } from "state";
import { useSelector } from "state";
import createMemoizedSplit from "helper/createMemoizedSplit";

const splitEvents = createMemoizedSplit("|");
export default React.memo(function Instrument({ instrument }) {
  const instrumentObject = useSelector(getInstrument(instrument));
  if (!instrumentObject) return null;
  return (
    <Row {...styles.fullWidth}>
      <InstrumentControl instrument={instrument} />
      <View {...styles.flexOne}>
        {splitEvents(instrumentObject.events).map((event, index) => {
          return (
            <Beat
              key={index}
              event={event}
              index={index}
              instrument={instrument}
              isPiano={instrumentObject.isPianoRollEnabled}
            />
          );
        })}
      </View>
    </Row>
  );
});
