const globalCache = new Map();
const EMPTY_ARRAY = [];
const createMemoizedSplit = separator => {
  const cache = globalCache.has(separator)
    ? globalCache.get(separator)
    : globalCache.set(separator, new Map()) && globalCache.get(separator);
  return str => {
    if (!str) {
      return EMPTY_ARRAY;
    }
    return cache.has(str)
      ? cache.get(str)
      : console.log("saving cache for", str) ||
          (cache.set(str, str.split(separator)) && cache.get(str));
  };
};
global.cache = globalCache;
export default createMemoizedSplit;
