import React, {
  useEffect,
  useMemo,
  createContext,
  useContext,
  useCallback,
  useState
} from "react";
import { useSelector, useDispatch } from "state";
import Tone from "tone";
import createArray from "helper/createArray";
import { durations, notes } from "config";
import createMemoizedSplit from "helper/createMemoizedSplit";
import Recorder from "recorder-js";

const ToneContext = createContext();

const body = document.body;

const splitBanks = createMemoizedSplit("|");
const splitEvents = createMemoizedSplit("|");
const splitEvent = createMemoizedSplit("_");

function enableLoop() {
  Tone.Transport.loop = true;
}

function disableLoop() {
  Tone.Transport.loop = false;
}

function startPlaying() {
  Tone.Transport.start();
}

export function ToneProvider({ children, banks }) {
  const sounds = useMemo(
    () =>
      splitBanks(banks).reduce((res, instrument) => {
        res[instrument] =
          instrument.startsWith("/") || instrument.startsWith("http")
            ? new Tone.Sampler({ C3: instrument }).toMaster()
            : new Tone[instrument]().toMaster();
        return res;
      }, {}),
    [banks]
  );
  const state = useSelector(e => e);
  const [isPlaying, setIsPlaying] = useState(true);

  const { bpm, resolution, beats, instruments } = state;
  const dispatch = useDispatch();

  const instrumentKeys = useMemo(() => Object.keys(instruments), [instruments]);

  const cancelEvents = useCallback(() => {
    Tone.Transport.cancel();
    instrumentKeys.forEach(instrument => {
      sounds[instrument].triggerRelease();
    });
  }, [sounds, instrumentKeys]);
  const toneResolution = useMemo(() => Tone.Time(`${resolution}n`), [
    resolution
  ]);

  const scheduleEvents = useCallback(
    dest => {
      createArray(beats, index => {
        Tone.Transport.schedule(function() {
          requestIdleCallback(() => {
            body.className = "step-" + index;
          });
        }, index * toneResolution);
      });
      for (const instrument of instrumentKeys) {
        const instrumentObject = instruments[instrument];
        if (!instrumentObject) {
          return null;
        }
        if (dest) {
          sounds[instrument].connect(dest);
        }
        splitEvents(instrumentObject.events).forEach((text, index) => {
          const event = splitEvent(text);
          if (event[0] === "1" && !instrumentObject.isMuted) {
            Tone.Transport.schedule(time => {
              sounds[instrument].triggerAttackRelease(event[1], event[2], time);
            }, index * toneResolution);
          }
        });
      }
      return () => {
        cancelEvents();
      };
    },
    [instruments, instrumentKeys, toneResolution, sounds, beats, cancelEvents]
  );

  const exportToWav = useCallback(() => {
    const audioContext = Tone.context;
    const dest = audioContext.createMediaStreamDestination();
    const recorder = new Recorder(audioContext);

    recorder.init(dest.stream);

    Tone.Transport.schedule(() => {
      cancelEvents();
      scheduleEvents(dest);
      recorder.start();
      disableLoop();
      Tone.Transport.schedule(() => {
        cancelEvents();
        scheduleEvents();
        enableLoop();
        startPlaying();
        console.log("stop recording");
        recorder.stop().then(({ blob }) => {
          Recorder.download(blob, `drumino-${Date.now()}`);
        });
      }, (beats * 2 + 1) * toneResolution);
      console.log("start recording");
    }, 0);
  }, [scheduleEvents, beats, toneResolution, cancelEvents]);

  useEffect(() => {
    Tone.Transport.bpm.value = bpm;
  }, [bpm]);

  useEffect(() => {
    if (isPlaying) {
      enableLoop();
      startPlaying();
    } else {
      Tone.Transport.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    Tone.Transport.loopEnd = beats * toneResolution;
  }, [toneResolution, beats]);

  useEffect(() => {
    return scheduleEvents();
  }, [scheduleEvents]);
  return (
    <ToneContext.Provider
      value={useMemo(
        () => ({
          dispatch,
          banks,
          notes,
          durations,
          sounds,
          exportToWav,
          setIsPlaying,
          isPlaying
        }),
        [dispatch, banks, sounds, exportToWav, isPlaying]
      )}
    >
      {children}
    </ToneContext.Provider>
  );
}

export default function useTone() {
  return useContext(ToneContext);
}
