import React, { useMemo } from "react";
import Button, { theme as buttonTheme } from "components/Button";
import Column from "components/Column";
import Row from "components/Row";
import Separator from "components/Separator";
import useTone from "hooks/useTone";
import { css } from "glamor";
import { useSelector, getInstrument } from "state";
import colors from "material-colors";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import * as scaleDictionary from "@tonaljs/scale-dictionary";
const scales = scaleDictionary
  .entries()
  .map(e => ({ ...e, name: e.name.toUpperCase() }))
  .sort((a, b) => (a.name > b.name ? 1 : -1));
const scaleKeys = ["A", "B", "C", "D", "E", "F", "G"];

export default React.memo(function InstrumentControl({ instrument, style }) {
  const { dispatch, sounds } = useTone();
  const instrumentObject = useSelector(getInstrument(instrument));
  const selectStyle = useMemo(
    () => ({
      ...buttonTheme.outline,
      ...css({
        margin: 4,
        fontSize: "inherit",
        cursor: "pointer"
      })
    }),
    []
  );
  if (!instrumentObject) return null;
  const { scale, scaleKey } = instrumentObject;

  return (
    <div style={style}>
      <div {...css({ padding: 4 })}>
        <Column
          {...css({
            justifyContent: "center",
            padding: 8,
            backgroundColor: colors.grey[900],
            margin: 0,
            borderRadius: 8
          })}
        >
          <Button
            theme="squareTransparent"
            {...css({
              flex: 1,
              alignItems: "flex-start",
              fontSize: 18,
              textAlign: "left"
            })}
            title={instrument}
            {...css({ alignItems: "flex-start" })}
            onPress={() => {
              sounds[instrument].triggerAttackRelease("C3", "16n");
            }}
          />
          <Separator />

          <Row>
            <Button
              title="Clear"
              {...css({ alignItems: "flex-start" })}
              onPress={() =>
                dispatch({ type: "clearInstrument", payload: { instrument } })
              }
            />
            <Button
              {...css({ alignItems: "flex-start" })}
              onPress={() =>
                dispatch({ type: "muteInstrument", payload: { instrument } })
              }
              title="Mute"
              {...css({
                backgroundColor: instrumentObject.isMuted
                  ? colors.red[500]
                  : null
              })}
            />
            <Button
              title={instrumentObject.isPianoRollEnabled ? "Beats" : "Piano"}
              {...css({ alignItems: "flex-start" })}
              onPress={() =>
                dispatch({
                  type: "togglePianoInstrument",
                  payload: { instrument }
                })
              }
            />
          </Row>
          <Separator />

          <Button
            {...css({ alignItems: "flex-start" })}
            title="Scale"
            theme="squareTransparent"
          />
          <Row {...css({ paddingLeft: 8, paddingRight: 8 })}>
            <Menu>
              <MenuButton {...selectStyle}>
                {scaleKey ? scaleKey : "C"} <span aria-hidden>▾</span>
              </MenuButton>
              <MenuList>
                {scaleKeys.map(scaleKey => (
                  <MenuItem
                    key={scaleKey}
                    onSelect={() =>
                      dispatch({
                        type: "setInstrumentScaleKey",
                        payload: { instrument, scaleKey }
                      })
                    }
                  >
                    {scaleKey}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton {...selectStyle} {...css({ flex: 1 })}>
                {scale} <span aria-hidden>▾</span>
              </MenuButton>
              <MenuList>
                {scales.map(scale => (
                  <MenuItem
                    key={scale.name}
                    onSelect={() =>
                      dispatch({
                        type: "setInstrumentScale",
                        payload: { instrument, scale: scale.name }
                      })
                    }
                  >
                    {scale.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Row>
          <Separator />
          <Button
            {...css({ alignItems: "flex-start" })}
            title="Randomize"
            theme="squareTransparent"
          />
          <Row>
            <Button
              {...css({ alignItems: "flex-start" })}
              onPress={() =>
                dispatch({ type: "setRandomBeat", payload: { instrument } })
              }
              title="BEAT"
            />
            <Button
              {...css({ alignItems: "flex-start" })}
              onPress={() =>
                dispatch({ type: "setRandomNote", payload: { instrument } })
              }
              title="NOTE"
            />
            <Button
              {...css({ alignItems: "flex-start" })}
              onPress={() =>
                dispatch({ type: "setRandomDuration", payload: { instrument } })
              }
              title="Dur"
            />
          </Row>
        </Column>
      </div>
    </div>
  );
});
