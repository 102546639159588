import React, { useMemo } from "react";
import { TextInput } from "react-native";
import colors from "material-colors";
import useWindowSize from "hooks/useWindowSize";
import Button from "components/Button";
import View from "components/View";
import Beat from "components/Beat";
import Text from "components/Text";
import Row from "components/Row";
import Column from "components/Column";
import Instrument from "components/Instrument";
import Logo from "components/Logo";
import InstrumentControl from "components/InstrumentControl";
import * as styles from "styles";
import { css } from "glamor";
import * as config from "config";
import { VariableSizeGrid as Grid, FixedSizeList as List } from "react-window";
import createMemoizedSplit from "helper/createMemoizedSplit";
import useTone from "hooks/useTone";

import {
  exportState,
  importState,
  useSelector,
  useDispatch,
  getBPM,
  getResolution,
  getInstruments,
  getBeats,
  getNotes,
  getSavedStates
} from "state";

const Label = props => (
  <Text {...css({ fontSize: 8, color: "white" })} {...props} />
);

const Input = props => (
  <TextInput
    {...css({
      fontSize: 16,
      color: "white",
      borderBottomColor: "white",
      borderBottomWidth: 1
    })}
    {...props}
  />
);

function BeatsInput() {
  const beats = useSelector(getBeats);
  const dispatch = useDispatch();
  return (
    <Column
      {...css({
        padding: 8,
        justifyContent: "center"
      })}
    >
      <Label>BEATS</Label>
      <Input
        keyboardType={"numeric"}
        placeholder="Beats"
        onChangeText={text =>
          dispatch({
            type: "setBeats",
            payload: Number(text)
          })
        }
        value={String(beats)}
      />
    </Column>
  );
}

function ResolutionInput() {
  const resolution = useSelector(getResolution);
  const dispatch = useDispatch();
  return (
    <Column
      {...css({
        padding: 8,
        justifyContent: "center"
      })}
    >
      <Label>Resolution</Label>
      <Input
        keyboardType={"numeric"}
        placeholder="Resolution"
        onChangeText={text =>
          dispatch({ type: "setResolution", payload: text })
        }
        value={String(resolution)}
      />
    </Column>
  );
}

const BPMInput = () => {
  const bpm = useSelector(getBPM);
  const dispatch = useDispatch();
  return (
    <Column
      {...css({
        padding: 8,
        justifyContent: "center"
      })}
    >
      <Label>Tempo</Label>
      <Input
        keyboardType={"numeric"}
        placeholder="bpm"
        onChangeText={text => dispatch({ type: "setBPM", payload: text })}
        value={String(bpm)}
      />
    </Column>
  );
};

const splitBanks = createMemoizedSplit("|");

const InstrumentRow = ({ data, index, style }) => {
  const instruments = useSelector(getInstruments);
  const dispatch = useDispatch();
  const instrument = data[index];
  return (
    <div style={style}>
      <Button
        key={instrument}
        {...css({
          alignItems: "flex-start",
          flex: 1,
          width: "100%",
          wordBreak: "initial",
          minWidth: "auto"
        })}
        theme={instruments[instrument] ? "squareYellow" : "squareTransparent"}
        title={instrument}
        onPress={() => {
          dispatch({
            type: "toggleInstrument",
            payload: { instrument }
          });
        }}
      />
    </div>
  );
};
const InstrumentSelector = () => {
  const windowSize = useWindowSize();
  const banks = splitBanks(config.banks);
  const active = useSelector(state => state.showInstruments);
  const dispatch = useDispatch();
  const hideInstruments = () => dispatch({ type: "hideInstruments" });
  return (
    <Column
      onClick={hideInstruments}
      {...css({
        width: 320,
        overflow: "hidden",
        position: "fixed",
        top: 0,
        left: active ? 0 : "-100%",
        bottom: 0,
        backgroundColor: "black",
        transition: "250ms",
        zIndex: 1
      })}
    >
      <Row>
        <Button
          title="Instruments"
          theme="squareTransparent"
          {...css({
            alignItems: "flex-start",
            fontSize: 18,
            wordBreak: "break-all",
            whiteSpace: "initial"
          })}
        />
        <Button
          title="&times;"
          theme="squareTransparent"
          {...css({ flex: 0, fontSize: 18, minWidth: 32 })}
        />
      </Row>
      <List
        itemData={banks}
        height={windowSize.height - 77}
        itemCount={banks.length}
        itemSize={36}
      >
        {InstrumentRow}
      </List>
    </Column>
  );
};

const LoopSelector = () => {
  const active = useSelector(state => state.showLoopSelector);
  const dispatch = useDispatch();
  const hideLoopSelector = () => dispatch({ type: "hideLoopSelector" });

  return (
    <Column
      onClick={hideLoopSelector}
      {...css({
        width: 320,
        overflow: "hidden",
        position: "fixed",
        top: 0,
        left: active ? 0 : "-100%",
        bottom: 0,
        background: "black",
        transition: "250ms"
      })}
    >
      <Row>
        <Button
          title="Loops"
          theme="squareTransparent"
          {...css({
            alignItems: "flex-start",
            fontSize: 18,
            wordBreak: "break-all",
            whiteSpace: "initial"
          })}
        />
        <Button
          title="&times;"
          theme="squareTransparent"
          {...css({ flex: 0, fontSize: 18, minWidth: 32 })}
        />
      </Row>
      <SavedStateList />
    </Column>
  );
};

function ExportAudioButton() {
  const { exportToWav } = useTone();
  return <Button onPress={exportToWav} title="Export Audio" />;
}

function StartStopButton() {
  const { isPlaying, setIsPlaying } = useTone();
  return (
    <Button
      onPress={() => setIsPlaying(e => !e)}
      title={isPlaying ? "Stop" : "start"}
    />
  );
}

function GlobalMenu() {
  const dispatch = useDispatch();
  return (
    <div {...css({ flex: 1 })}>
      <Row>
        <Column>
          <Button
            onPress={() => dispatch({ type: "showInstruments" })}
            title="Instruments"
          />
        </Column>
        <Column>
          <Button
            onPress={() => dispatch({ type: "showLoopSelector" })}
            title="Loops"
          />
        </Column>
        <Column>
          <Button onPress={() => dispatch({ type: "undo" })} title="Undo" />
        </Column>
        <Column>
          <Button onPress={() => dispatch({ type: "redo" })} title="Redo" />
        </Column>
        <Column>
          <Button
            onPress={() => dispatch({ type: "clearBeat" })}
            title="Clear"
          />
        </Column>
        <Column>
          <Button
            onPress={() =>
              dispatch({ type: "setRandomBeat", payload: { instrument: null } })
            }
            title="Random"
          />
        </Column>
        <Column>
          <Button onPress={importState} title="Import State" />
        </Column>
        <Column>
          <Button onPress={exportState} title="Export State" />
        </Column>

        <Column>
          <ExportAudioButton />
        </Column>

        <Column>
          <Button
            onPress={() =>
              dispatch({ type: "saveState", payload: { time: Date.now() } })
            }
            title="Save Loop"
          />
        </Column>
        <Column>
          <StartStopButton />
        </Column>
        <View {...css({ flex: 1 })} />

        <Column>
          <BeatsInput />
        </Column>
        <Column>
          <ResolutionInput />
        </Column>
        <Column>
          <BPMInput />
        </Column>
      </Row>
    </div>
  );
}

function Header() {
  return (
    <Column
      style={{
        background: "rgba(0,0,0,0.2)",
        maxWidth: "100%",
        overflow: "auto"
      }}
    >
      <Row>
        <GlobalMenu />
      </Row>
    </Column>
  );
}

const AppContainer = props => {
  const { width, height } = useWindowSize();
  return (
    <Column
      {...css({
        width,
        height,
        backgroundColor: colors.grey[900],
        overflow: "hidden"
      })}
      {...props}
    ></Column>
  );
};

const splitEvents = createMemoizedSplit("|");
const LandscapeCell = ({ columnIndex, rowIndex, style }) => {
  const instruments = useSelector(getInstruments);
  const instrumentKey = Object.keys(instruments)[rowIndex];
  const instrumentObject = instruments[instrumentKey];
  if (!instrumentObject) return null;
  const events = instrumentObject.events;

  if (!events) {
    return null;
  }

  const index = columnIndex - 1;
  const event = splitEvents(events)[index];
  if (columnIndex) {
    return (
      <Beat
        style={style}
        instrument={instrumentKey}
        event={event}
        index={index}
        isPiano={instrumentObject.isPianoRollEnabled}
      />
    );
  }
  return <InstrumentControl instrument={instrumentKey} style={style} />;
};

const PortraitCell = ({ columnIndex, rowIndex, style }) => {
  const instruments = useSelector(getInstruments);
  const instrumentKey = Object.keys(instruments)[columnIndex];
  const instrumentObject = instruments[instrumentKey];
  if (!instrumentObject) return null;
  const events = instrumentObject.events;

  if (!events) {
    return null;
  }

  const index = rowIndex - 1;
  const event = splitEvents(events)[index];
  if (rowIndex) {
    return (
      <Beat
        style={style}
        instrument={instrumentKey}
        event={event}
        index={index}
        isPiano={instrumentObject.isPianoRollEnabled}
      />
    );
  }
  return <InstrumentControl instrument={instrumentKey} style={style} />;
};

const SavedStateRow = ({ index, style }) => {
  const dispatch = useDispatch();
  const state = useSelector(getSavedStates)[index];
  return (
    <View style={style}>
      <View {...css({ margin: 4, flex: 1 })}>
        <Button
          onPress={() => dispatch({ type: "setState", payload: state.state })}
        >
          {new Date(state.time).toJSON()}
        </Button>
      </View>
    </View>
  );
};

export function SavedStateList() {
  const windowSize = useWindowSize();
  const savedStates = useSelector(getSavedStates);

  return (
    <List
      itemCount={savedStates.length}
      itemSize={44}
      width={220}
      height={windowSize.height - 77}
    >
      {SavedStateRow}
    </List>
  );
}

const splitNotes = createMemoizedSplit("|");
export default React.memo(function GridApp() {
  const windowSize = useWindowSize();
  const beats = useSelector(getBeats);
  const instruments = useSelector(getInstruments);
  const notes = useSelector(getNotes);
  const enableGrid = true;
  const isPortrait = windowSize.width < windowSize.height;
  const pianoRollHeight = splitNotes(notes).length * 30;
  const instrumentCount = Object.keys(instruments).length;
  const beatCount = beats + 1;
  const columnCount = isPortrait ? instrumentCount : beatCount;
  const beatWidth = Math.max(
    isPortrait ? 128 : (windowSize.width - 240) / 16,
    64
  );
  const getColumnWidth = index =>
    isPortrait
      ? instruments[Object.keys(instruments)[index]].isPianoRollEnabled
        ? pianoRollHeight
        : 240
      : index
      ? beatWidth
      : 240;
  const getRowHeight = index =>
    isPortrait
      ? index
        ? beatWidth
        : 320
      : instruments[Object.keys(instruments)[index]].isPianoRollEnabled
      ? pianoRollHeight
      : 320;
  const rowCount = isPortrait ? beatCount : instrumentCount;
  const columnHeight = () => windowSize.height;
  const gridKey = useMemo(() => {
    return Object.keys(instruments)
      .map(key => instruments[key].isPianoRollEnabled)
      .join("");
  }, [instruments]);
  return (
    <AppContainer>
      <Header />
      <Row>
        <InstrumentSelector />
        {enableGrid ? (
          <Grid
            key={gridKey}
            overscanRowCount={2}
            overscanColumnCount={2}
            columnCount={columnCount}
            columnWidth={getColumnWidth}
            columnHeight={columnHeight}
            rowCount={rowCount}
            rowHeight={getRowHeight}
            width={windowSize.width}
            height={windowSize.height - 77}
          >
            {isPortrait ? PortraitCell : LandscapeCell}
          </Grid>
        ) : (
          <View
            {...css({
              flex: 1,
              overflow: "auto",
              padding: 8
            })}
          >
            <Column {...styles.flexOne}>
              {instruments.map(instrument => {
                return <Instrument key={instrument} instrument={instrument} />;
              })}
            </Column>
          </View>
        )}
        <LoopSelector />
      </Row>
    </AppContainer>
  );
});
