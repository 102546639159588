import React from "react";
import Button from "components/Button";
import createArray from "helper/createArray";
import Text from "components/Text";
import Column from "components/Column";
import { css } from "glamor";

const buttonClassName = "hb";
const style = document.createElement("style");
style.innerHTML = createArray(1280, index => {
  return `
    .step-${index} button.${buttonClassName}-${index} {opacity:0.5 !important;}
    `;
}).join("");
document.head.appendChild(style);

export default function HighlightButton({
  on,
  index,
  resolution,
  interval,
  highlighted,
  ...props
}) {
  const style = {
    position: "relative",
    wordBreak: "break-all"
  };
  return (
    <Button
      theme={on ? "yellow" : "grey"}
      className={`${buttonClassName}-${index}`}
      {...props}
      {...css({ wordBreak: "break-all", whiteSpace: "normal" })}
      title={props.title}
      children={
        <Column>
          <Text style={{ position: "absolute", bottom: 4, right: 4 }}>
            {index + 1}
          </Text>

          <Text>{props.title}</Text>
        </Column>
      }
      style={style}
    />
  );
}
