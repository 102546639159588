import { css } from "glamor";
import colors from "material-colors";
export const centeredWhiteTextInput = css({
  color: "white",
  alignItems: "center"
});
export const column = css({ flexDirection: "column" });
export const row = css({ flexDirection: "row" });
export const positionRelative = css({ position: "relative" });
export const blackBorderColor = css({ borderColor: colors.grey[900] });
export const positionAbsolute = css({ position: "absolute" });
export const flexOne = css({ flex: 1 });
export const fullWidth = css({ width: "100%" });
