const arrays = new Map();
const createArray = (number, mapper = e => e) => {
  let array;
  if (arrays.has(number)) {
    array = arrays.get(number);
  } else {
    array = Array.from({ length: number }, (__, index) => index);
  }
  return array.map(mapper);
};

export default createArray;
