import React from "react";
import { css } from "glamor";

const View = props => (
  <div
    {...props}
    {...css({
      display: "flex",
      boxSizing: "border-box",
      textTransform: "uppercase",
      fontFamily: "sans-serif",
      letterSpacing: 2
    })}
  />
);
export default View;
