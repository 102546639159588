import React from "react";
import ReactDOM from "react-dom";
import { StateProvider } from "state";
import { ToneProvider } from "hooks/useTone";
import { WindowSizeProvider } from "hooks/useWindowSize";
import App from "components/App";
import * as config from "config";
import * as serviceWorker from "serviceWorker";
import "@reach/menu-button/styles.css";
import "./style.css";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
const rootElement = document.getElementById("root");
ReactDOM.render(
  <WindowSizeProvider>
    <StateProvider>
      <ToneProvider banks={config.banks}>
        <App />
      </ToneProvider>
    </StateProvider>
  </WindowSizeProvider>,
  rootElement
);
