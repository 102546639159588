import React, { useMemo, createContext, useContext } from "react";
import { useSubscription } from "use-subscription";

const WindowSizeContext = createContext({});

export default function useWindowSize() {
  return useContext(WindowSizeContext);
}

export function WindowSizeProvider({ children }) {
  const value = useSubscription(
    useMemo(
      () => ({
        getCurrentValue: () => ({
          height: window.innerHeight,
          width: window.innerWidth
        }),
        subscribe: callback => {
          window.addEventListener("resize", callback);
          return () => window.removeEventListener("resize", callback);
        }
      }),
      []
    )
  );
  return (
    <WindowSizeContext.Provider value={value}>
      {children}
    </WindowSizeContext.Provider>
  );
}
