import React from "react";
import createSelector from "selectorator";
import { durations, notes } from "config";
// import { useRef, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { scale } from "@tonaljs/scale";
import createArray from "helper/createArray";
import createMemoizedSplit from "helper/createMemoizedSplit";
import immer from "immer";
export { useSelector, useDispatch } from "react-redux";

export const getBPM = createSelector(["bpm"]);
export const getResolution = createSelector(["resolution"]);
export const getBeats = createSelector(["beats"]);
export const getInstruments = createSelector(["instruments"]);
export const getCurrentTick = createSelector(["currentTick"]);

export const getCalculatedNotes = notes => {
  const calculatedNotes = [];
  for (const index of createArray(6)) {
    for (const note of notes) {
      calculatedNotes.push(`${note}${index}`);
    }
  }
  return calculatedNotes;
};

const hash = decodeURIComponent(window.location.hash.slice(1));
const EMPTY_OBJECT = {};

export const getInstrumentEvents = instrument => {
  return state => getInstrument(instrument)(state).events;
};

const subscribers = new Set();

function init(initialState) {
  const state = {
    bpm: 120,
    resolution: 16,
    beats: 16,
    instruments: {},
    notes,
    durations,
    savedStates: []
  };
  Object.assign(state, initialState);
  return state;
}

const state = {
  current: init(hash ? JSON.parse(hash) : EMPTY_OBJECT)
};

export function createStoreWorker(reducerUrl) {
  const worker = new Worker(reducerUrl);

  worker.postMessage({
    type: "INIT",
    payload: state.current
  });

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  function setState(newState) {
    state.current = newState;
    console.log(state.current);
    requestIdleCallback(() => {
      shuffle([...subscribers]).forEach(cb => {
        cb();
      });
    });
  }

  function asyncParse(string) {
    return new Response(string).json();
  }

  worker.onmessage = event => {
    asyncParse(event.data).then(setState);
  };

  const dispatch = action => {
    console.log(action);
    worker.postMessage(action);
  };
  return {
    dispatch,
    getState() {
      return state.current;
    }
  };
}

// export const useDispatch = () => dispatch;
// export const useSelector = selector => {
//   const [computedState, setComputedState] = useState(() =>
//     selector(state.current)
//   );
//   const savedSelector = useRef();
//   useEffect(() => {
//     savedSelector.current = selector;
//   }, [selector]);
//   useEffect(() => {
//     let mounted = true;
//     const handler = () => {
//       if (mounted) {
//         setComputedState(savedSelector.current(state.current));
//       }
//     };
//     subscribers.add(handler);
//     return () => {
//       mounted = false;
//       subscribers.delete(handler);
//     };
//   }, []);
//   return computedState;
// };

export const exportState = () => {
  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(store.getState()));
  const link = document.createElement("a");
  link.setAttribute("href", dataStr);
  link.setAttribute("download", `drumino-${Date.now()}.json`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
export const importState = () => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.onchange = () => {
    const reader = new FileReader();
    reader.onload = () => {
      store.dispatch({
        type: "replaceState",
        payload: JSON.parse(reader.result)
      });
    };
    reader.readAsText(input.files[0]);
    input.remove();
  };
  document.body.appendChild(input);
  input.click();
};

export const getDurations = createSelector(["durations"]);
export const getNotes = createSelector(["notes"]);
export const getPianoInstruments = createSelector(["pianoInstruments"]);
export const getVolumes = createSelector(["volumes"]);
export const getSavedStates = createSelector(["savedStates"]);

const createSelectorFor = root => {
  const getForCache = new Map();
  const getFor = key => {
    if (!getForCache.has(key)) {
      getForCache.set(
        key,
        createSelector(
          [root],
          e => e && e[key]
        )
      );
    }
    return getForCache.get(key);
  };
  return getFor;
};

export const getInstrument = createSelectorFor("instruments");
export const getEvents = instrument =>
  createSelector(
    [createSelectorFor("instruments")(instrument)],
    instrument => instrument && instrument.events
  );

const history = [];

const KEY_SEPARATOR = "_";
const BEAT_SEPARATOR = "|";

const beatSeparatorSplit = createMemoizedSplit(BEAT_SEPARATOR);
const keySeparatorSplit = createMemoizedSplit(KEY_SEPARATOR);

const getInstrumentEvent = (state, instrument, index) =>
  keySeparatorSplit(
    beatSeparatorSplit(getInstrumentEvents(instrument)(state))[index]
  );

// const setAt = (index, value, array) => {
//   const newArray = [...array];
//   array[index] = value;
//   return newArray;
// };

let pointer = 0;
function wrapWithUndo(realReducer) {
  return (state, action) => {
    console.log({ history, pointer });
    if (action.type === "undo") {
      pointer = Math.max(0, pointer - 1);
      return history[pointer] || state;
    }
    if (action.type === "redo") {
      pointer = Math.min(pointer + 1, history.length - 1);
      return history[pointer] || state;
    }
    const finalState = realReducer(state, action);
    if (pointer !== history.length) {
      history.length = pointer + 1;
    }
    pointer = history.push(finalState);
    if (history.length > 100) {
      history.splice(history.length - 100, 100);
    }
    pointer = history.length - 1;
    return finalState;
  };
}

function reducer(state, { type, payload = {} }) {
  return immer(state, draft => {
    switch (type) {
      case "INIT":
        return payload;
      case "showInstruments":
        return { ...state, showLoopSelector: false, showInstruments: true };
      case "hideInstruments":
        return { ...state, showInstruments: false };
      case "showLoopSelector":
        return { ...state, showInstruments: false, showLoopSelector: true };
      case "hideLoopSelector":
        return { ...state, showLoopSelector: false };
      case "replaceState":
        return payload;
      case "setState":
        return { ...state, ...payload, savedStates: state.savedStates };
      case "saveState":
        draft.savedStates.push({ time: payload.time, state });
        break;
      case "setInstrumentScale":
        draft.instruments[payload.instrument].scale = payload.scale;
        break;
      case "setInstrumentScaleKey":
        draft.instruments[payload.instrument].scaleKey = payload.scaleKey;
        break;
      case "muteInstrument":
        draft.instruments[payload.instrument].isMuted = !state.instruments[
          payload.instrument
        ].isMuted;
        break;
      case "togglePianoInstrument":
        draft.instruments[payload.instrument].isPianoRollEnabled = !state
          .instruments[payload.instrument].isPianoRollEnabled;
        break;
      case "setBPM":
        draft.bpm = payload;
        break;
      case "setResolution":
        draft.resolution = payload;
        break;
      case "setBeats":
        draft.beats = payload;
        break;
      case "toggleInstrument": {
        const currentInstrument = getInstrument(payload.instrument)(state);
        if (currentInstrument) {
          delete draft.instruments[payload.instrument];
        } else {
          draft.instruments[payload.instrument] = {
            scaleKey: "C",
            scale: "CHROMATIC",
            events: createTimeline(state.beats, getDefaultEvent)
          };
        }
        break;
      }
      case "clearBeat": {
        if (payload.instrument) {
          draft.instruments[payload.instrument].events = createTimeline(
            state.beats,
            getDefaultEvent
          );
        } else {
          for (const key of Object.keys(state.instruments)) {
            draft.instruments[key].events = createTimeline(
              state.beats,
              getDefaultEvent
            );
          }
        }
        break;
      }
      case "setRandomDuration": {
        draft.instruments[payload.instrument].events = createTimeline(
          state.beats,
          index =>
            getInstrumentEvent(state, payload.instrument, index)
              .map((e, index) =>
                index === 2
                  ? beatSeparatorSplit(state.durations)[
                      Math.floor(
                        Math.random() *
                          beatSeparatorSplit(state.durations).length
                      )
                    ]
                  : e
              )
              .join(KEY_SEPARATOR)
        );
        break;
      }
      case "setRandomNote": {
        const notes = scale(
          [
            getInstrument(payload.instrument)(state).scaleKey,
            getInstrument(payload.instrument)(state).scale.toLowerCase()
          ].join(" ")
        ).notes;
        draft.instruments[payload.instrument].events = createTimeline(
          state.beats,
          index =>
            getInstrumentEvent(state, payload.instrument, index)
              .map((e, index) => {
                if (index === 1) {
                  const calculatedNotes = getCalculatedNotes(notes);
                  return calculatedNotes[
                    Math.floor(Math.random() * calculatedNotes.length)
                  ];
                }
                return e;
              })
              .join(KEY_SEPARATOR)
        );
        break;
      }
      case "setRandomBeat": {
        const createRandomBeatTimeline = instrument =>
          createTimeline(state.beats, index =>
            getInstrumentEvent(state, instrument, index)
              .map((e, i) =>
                i === 0 ? (Math.round(Math.random()) ? "1" : "") : e
              )
              .join(KEY_SEPARATOR)
          );
        if (payload.instrument) {
          draft.instruments[
            payload.instrument
          ].events = createRandomBeatTimeline(payload.instrument);
        } else {
          for (const instrument of Object.keys(state.instruments)) {
            draft.instruments[instrument].events = createRandomBeatTimeline(
              instrument
            );
          }
        }
        break;
      }
      case "setInstrumentNote": {
        draft.instruments[payload.instrument].events = beatSeparatorSplit(
          getInstrumentEvents(payload.instrument)(state)
        )
          .map((e, i) =>
            i === payload.index
              ? keySeparatorSplit(e)
                  .map((k, i) => {
                    if (i === 1) {
                      return payload.note;
                    }
                    return k;
                  })
                  .join(KEY_SEPARATOR)
              : e
          )
          .join(BEAT_SEPARATOR);
        break;
      }
      case "setInstrumentDuration": {
        draft.instruments[payload.instrument].events = beatSeparatorSplit(
          getInstrumentEvents(payload.instrument)(state)
        )
          .map((e, i) =>
            i === payload.index
              ? keySeparatorSplit(e)
                  .map((k, i) => {
                    if (i === 2) {
                      return payload.duration;
                    }
                    return k;
                  })
                  .join(KEY_SEPARATOR)
              : e
          )
          .join(BEAT_SEPARATOR);
        break;
      }
      case "toggleInstrumentOn": {
        draft.instruments[payload.instrument].events = beatSeparatorSplit(
          getInstrumentEvents(payload.instrument)(state)
        )
          .map((e, i) =>
            i === payload.index
              ? keySeparatorSplit(e)
                  .map((k, i) => {
                    if (i === 0) {
                      return k ? "" : "1";
                    }
                    return k;
                  })
                  .join(KEY_SEPARATOR)
              : e
          )
          .join(BEAT_SEPARATOR);
        break;
      }
      case "clearInstrument": {
        draft.instruments[payload.instrument].events = createTimeline(
          state.beats,
          getDefaultEvent
        );
        break;
      }
      default:
        break;
    }
  });
}

const createTimeline = (beats, fn) =>
  createArray(beats, index => fn(index)).join(BEAT_SEPARATOR);

const getDefaultEvent = () => ["", "C3", "1n"].join(KEY_SEPARATOR);

const store = createStore(
  wrapWithUndo(reducer),
  init(hash ? JSON.parse(hash) : EMPTY_OBJECT),
  composeWithDevTools()
);
export const StateProvider = props => (
  <Provider store={store}>{props.children}</Provider>
);
