import React from "react";
import Text from "components/Text";
import { css } from "glamor";

const Logo = React.memo(function Logo() {
  return (
    <Text
      {...css({
        color: "white",
        alignItems: "center",
        fontSize: 24,
        fontWeight: 600,
        marginRight: 16
      })}
    >
      DRUMINO
    </Text>
  );
});

export default Logo;
